
































































.videoDetail {
    .content {
        margin-top: 0.3rem;
        margin-bottom: .5rem;

        @media (max-width: 767px) {
            margin: 0.1rem 0 0;
        }

        .video {
            width: 10rem;
            margin:0 auto 0;
			text-align: center;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 0.5rem;
            }

            video {
                width: 10rem;
                margin-left: auto;
				margin-right:auto;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .title {
            color: #333333;
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .text {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            margin-bottom: 0.2rem;
            text-align: center;
			&.textV{
				margin:.3rem auto 0;
			}
        }
    }
}
